import * as React from "react"
import Seo from "../components/seo"
import { RichText } from 'prismic-reactjs'
import { Link, graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import Copyright from "../components/copyright"
import { GatsbyImage } from "gatsby-plugin-image"

const AboutEntry = ({data}) => {
  const entry = data.prismicAbout
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={entry.url} />
      <Header />
      <div className="w-full pt-6 pb-6 border-b-2 border-grey">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="font-verdana text-md lg:text-lg">
            <Link to="/about/" className="border-b-2 border-black">About</Link> <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg> {entry.data.heading.text}
          </div>
        </div>
      </div>
      <div className="w-full pt-12 pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 items-start justify-between">
            <div className="w-full sm:w-6/12">
              <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight mb-12">
                <h1>{entry.data.heading.text}</h1>
              </div>
              <div className="font-ubuntu prose prose-xl leading-normal">
                {RichText.render(entry.data.text.richText)}
              </div>
              <div className="mt-12 inline-block">
                <div className="font-ubuntu text-2xl text-red border-2 border-red py-2 px-4 inline">
                  <Link to="/contact/">Make enquiry <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-6/12 mt-12 sm:mt-0">
              <GatsbyImage image={entry.data.image.gatsbyImageData} alt={entry.data.heading.text} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "contain" }} />
            </div>
          </div>
        </div>
      </div>
      {entry.data.logos.length !== '' && 
        <div className="w-full pt-12 pb-12 border-t-2 border-grey">
          <div className="w-11/12 mx-auto max-w-screen-2xl">
            <div className="flex-none sm:flex gap-12 justify-around items-center">
              {entry.data.logos.map((entry, i) => (
                <div key={`logo_${i}`} className="px-24 sm:px-0 lg:px-6 xl:px-12">
                  <GatsbyImage image={entry.logo.gatsbyImageData} alt={`logo_${i}`} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "contain" }} />
                </div>
              ))}
            </div>
          </div>
        </div>
      }
      <Footer />
      <Copyright />
    </>
  )
}

export const query = graphql`
query($handle: String) {
  prismicAbout(uid: {eq: $handle}) {
    data {
      text {
        richText
      }
      page_title
      meta_description
      heading {
        text
      }
      image {
        gatsbyImageData(width: 1024, height: 1024)
      }
      logos {
        logo {
          gatsbyImageData(width: 640, height: 480)
        }
      }
    }
    url
  }
}
`

export default AboutEntry